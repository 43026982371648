<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-2">
          <template #start>
      
             
              <Dropdown
                id="status"
                style="width:100%"
                v-model="submissionstatus"
                :options="submissionItems"
                :value="submissionItems.value"
                optionLabel="name"
                placeholder="Select Year"
                :class="{ 'p-invalid': submitted && !selectstatus }"
                required="true"
                v-on:change="get_list"
              ></Dropdown>
         
              <!-- <Button
                icon="pi pi-search"
                class="p-button-success mr-2"
                @click="get_list"
              /> -->
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->

              <!-- <Button
                icon="pi pi-sync"
                class="p-button-info mr-2"
                @click="refresh"
              /> -->
          
          </template>

          <template #end>
            <!-- <FileUpload
              mode="basic"
              accept="image/*"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
                label="Schedule Lecture"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>
     
        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        ><template #empty>
        <b style="text-align:center">No records found.</b>
      </template>
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Today's Lecture</b> </h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              <!-- <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Search..." />
                            </span> -->
            </div>
          </template>
          <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>
          <Column
            header="ID"
            :field="columns[6]"
            style="min-width: 5rem;text-align: right;"
          >
            <template #body="{ data }">
              {{ data.teacheR_ID }}
            </template>
          </Column>
          <Column header="Subject" :field="columns[1]">
            <template #body="{ data }">
              {{ data.subject }}
            </template>
          </Column>
          <Column header="Discussion" :field="columns[5]">
            <template #body="{ data }">
              {{ data.discuessioN_ON_TOPIC }}
            </template>
          </Column>
          <Column header="Schedule From" :field="columns[2]" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.schedulE_FROM }}
            </template>
          </Column>
       
          <Column header="Schedule To" :field="columns[3]" style="min-width: 10rem">
            <template #body="{ data }">
              {{ data.schedulE_TO }}
            </template>
          </Column>
          

          <Column header="View" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <!-- <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
               
              /> -->
              <Button label="View" class="p-button-outlined"  @click="editProduct(slotProps.data)" />
              <!-- <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              /> -->
            </template>
          </Column>
        </DataTable>


        <Sidebar
          v-model:visible="productDialog"
          :baseZIndex="1000"
          position="full"
        >
        <div class="col-12">
            <div class="card">
              <h5>Form on Today's Lecture </h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-3">
                  <label for="schedule">schedule</label>
                  <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="online_class.liveLectureDetails[0].schedule"
                />
                </div>
                <div class="field col-12 md:col-3">
                  <label for="teachername">Teacher name</label>
                  <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="online_class.teachername"
                />
                </div>
                <div class="field col-12 md:col-6">
                  <label for="Topic">Topic</label>
                  <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="online_class.topicname"
                />
                </div>
                </div>


              </div>
              </div>

              <div class="col-12">
            <div class="card">
              <div class="p-fluid formgrid grid">
              <div class="field col-12 md:col-6">
                  <div style="padding:56.25% 0 0 0;position:relative;"><iframe :src=online_class.liveLectureDetails[0].embeD_LINK frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
                </div>
                <div class="field col-12 md:col-6">
                  <iframe :src=online_class.liveLectureDetails[0].embeD_CHAT width="100%" height="500px" frameborder="0"></iframe>
                </div>
              </div>
              </div>
              </div>



        </Sidebar>


        <Dialog
          v-model:visible="productDialog5"
          :style="{ width: '800px', width: '800px' }"
          header="Teacher Remark On Student Answer"
          :modal="true"
          class="p-fluid"
        >
          <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="product.publisheD_BY"
                />
                <label for="inputtext">Schedule</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="product.publisheD_BY"
                />
                <label for="inputtext">Teacher Name</label>
              </span>
            </div>
            <div class="field col-12 md:col-6">
              <span class="p-float-label">
                <InputText
                  type="text"
                  id="inputtext"
                  required="true"
                  disabled
                  autofocus
                  v-model="product.publisheD_BY"
                />
                <label for="inputtext">Topic</label>
              </span>
            </div>
          
        </Dialog>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteVideo"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      uploadproductDialog: false,
      stdDialog: false,
      submissionItems: [],
      submissionstatus: { name: "", value: "" },

      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachmentsm: "",
      file_attachmentdpp: "",
      file_attachmentdppA: "",
      dropdownItems: [
        { name: "YES", value: "YES" },
        { name: "NO", value: "NO" },
      ],

      stdproducts: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      online_class:{},
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "", value: "" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    view_video(video) {
      // alert(video)
      window.open(video.data.embeD_LINK, "_blank");
    },
    view_std(info) {
      var data = {
        TSCH_DE_ID: info.tscH_DE_ID,
      };
      this.loading = true;
      var promise = apis.lectureattendedstudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.stdproducts = response.data;
        this.stdDialog = true;
      });
    },
    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name:
                info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR
            });
          }
        }
      });
    },
    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUploadsm() {
      this.file_attachmentsm = this.$refs.file_attachmentsm.files[0];

      //alert(this.$refs.file_attachmentsm.files[0].name);
    },
    handleFileUploaddpp() {
      this.file_attachmentdpp = this.$refs.file_attachmentdpp.files[0];

      //alert(this.$refs.file_attachmentdpp.files[0].name);
    },
    handleFileUploaddppA() {
      this.file_attachmentdppA = this.$refs.file_attachmentdppA.files[0];

      //alert(this.$refs.file_attachmentdppA.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      if (this.submissionstatus.value) {
        var data = {
          //id:parseInt(localStorage.getItem("id")),
         
          count: true,
          year: this.submissionstatus.value,
        };
        //this.loading = true;
        var promise = apis.todaylectureadminmaster(data, this.id);
        promise.then((response) => {
         // this.loading = false;
          console.log(response);
          this.totalRecords = response.data.length;
          //alert(this.totalRecords);
        });
      }
    },
    refresh() {
      this.product = null;
      this.submissionstatus = { name: "", value: "" };
    },
    async get_list() {
      if (this.submissionstatus.value) {
        var data = {
          // id:parseInt(localStorage.getItem("id")),
          Year: this.submissionstatus.value,
          page_no: this.page_no,
          limit: this.limit,
          count: false,
        };
        this.loading = true;
        var promise = apis.todaylectureadminmaster(data, this.id);
        promise.then((response) => {
          this.loading = false;
          console.log(response);
          this.products = response.data;
          console.log(this.products[0]);
          if (this.columns.length == 0) {
            if (this.products.length > 0) {
              this.columns = Object.keys(this.products[0]);
            }
          }
        });
        await this.get_count();
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {

      

      this.product = {};
      this.submitted = false;
      this.$router.push('/schedulelecture');
      // this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Updatesubmission() {
      this.submitted = true;
      if (!this.selectstatus.value) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please select check by teacher",
          life: 3000,
        });
        return false;
      }
      if (!this.product.teacheR_REMARK) {
        this.$toast.add({
          severity: "error",
          summary: "error",
          detail: "Please Enter Remark",
          life: 3000,
        });
        return false;
      }

      //edit
      if (this.product.tscH_DE_ID) {
        var data = {
          TSCH_DE_ID: this.product.tscH_DE_ID,
          STUDENT_ID: this.product.studenT_ID,
          TEACHER_REMARK: this.product.teacheR_REMARK,
          TEACHER_CHECK: this.selectstatus.value,
        };
        this.isLoadingModel = true;
        var promise = apis.updateteacherremark(data);
        promise
          .then((responseapi) => {
            this.$swal(responseapi.data.message);
            this.isLoadingModel = false;
            this.productDialog = false;
            this.get_list();
            this.get_count();
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    Schedule_Lecture_View(product) {
      
      
      
      var data = {
        //tscH_ID: product.tscH_ID,
        tscH_ID:4605
      };
this.isLoading = true;
      var promise = apis.schedulelectureview(data, this.id);
      promise.then((response) => {
        this.isLoading = false;
        if (response.data.liveLectureDetails) {
          this.online_class = response.data;
        }
        this.productDialog = true;
        
      });
      
    },
    editProduct(product) {
      this.product = { ...product };
      this.Schedule_Lecture_View(this.product);
      
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
